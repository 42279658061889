import {useSelector} from "react-redux";
import {selectAuth} from "../../containers/SingIn/authSlice";
import {useCallback, useEffect} from "react";
import {embedDashboard} from "@superset-ui/embedded-sdk";
import * as process from "process";
import {getSuperSetGuestToken} from "../../containers/SingIn/authAPI";

interface SupersetViewProps {
    dashboardId: string;
    showFilter?: boolean;
}

const SupersetView = ({ dashboardId, showFilter = true }: SupersetViewProps) => {
    const {org} = useSelector(selectAuth);
    const screenHeight = window.innerHeight;
    const supersetDomain = process.env.REACT_APP_SUPERSET_DOMAIN;

    const initSuperSetDashboard = useCallback(async () => {
        const guestTokenResponse = await getSuperSetGuestToken({
            resourceId: dashboardId,
            orgId: org!.id,
            resourceType: "dashboard",
        });
        await embedDashboard({
            id: dashboardId,
            supersetDomain: supersetDomain!,
            mountPoint: document.getElementById("dashboard")!,
            fetchGuestToken: () => guestTokenResponse.data.data.token,
            dashboardUiConfig: {
                hideTitle: true,
                hideTab: true,
                filters: {
                    expanded: false,
                    visible: true,
                },
                urlParams: {
                    standalone: showFilter ? 1 : 3,
                },
            },
        });

        // Adjust iframe dimensions
        const iframe = document.querySelector("iframe");
        if (iframe) {
            iframe.style.width = "100%";
            iframe.style.minHeight = `${screenHeight}px`;
        }
    }, [dashboardId, supersetDomain, org, screenHeight]);

    useEffect(() => {
        if (org && supersetDomain) {
            initSuperSetDashboard().then();
        }
    }, [initSuperSetDashboard, org, supersetDomain]);

    return (
        <div className="flex-1 w-full h-full min-h-screen">
            <div id="dashboard" className="min-h-screen"></div>
        </div>
    );
};
export default SupersetView;