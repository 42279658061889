const ThoughtFullSupport = () => {
    const screenHeight = window.innerHeight;
    return (
        <div className="iframe-wrapper mt-2 font-bold text-3xl text-primary" style={{height: screenHeight}}>
            <iframe
                id={`myiFrame_support`}
                title={`ThoughtFull Support`}
                key={`ThoughtFull Support`}
                className="w-full h-full min-h-full"
                src={'https://20866719.hs-sites.com/knowledge'}
                frameBorder="0"
                height={`${screenHeight}px`}
            ></iframe>
        </div>
    );
};

export default ThoughtFullSupport;