import SupersetView from "../../components/Superset";
import { PROGRAM_INSIGHTS} from "../../constants/superset";

const ActivationPrograms = () => {
    return (
        <div className="bg-grey min-h-screen">
            <SupersetView dashboardId={PROGRAM_INSIGHTS} showFilter={false}/>
        </div>
    );
}

export default ActivationPrograms