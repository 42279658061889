import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import MainApp from 'App/MainApp'
import { useDispatch, useSelector } from "react-redux";
import SignIn from "containers/SingIn";
import { selectAuth } from "containers/SingIn/authSlice";
import { fetchUserData } from "containers/SingIn/authAPI";
import { getToken } from "utils";
import GenToken from "containers/GenToken";
import useGaTracker from "../useGaTracker.js"
const RestrictedRoute = ({ component: Component, location, token, ...rest }: any) => {

  return (
    <Route
      {...rest}
      render={props =>
        token
          ? <Component {...props} />
          : <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />}
    />
  )
}



export default function App(props: any) {
  const { programId } = useSelector(selectAuth);
  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserData(props.history, programId));
  }, []);
  useGaTracker();
  return (
    <Router>
      <Switch>
        <Route exact path='/token' component={GenToken} />
        <Route exact path='/signin' component={SignIn} />
        <RestrictedRoute path={`${match.url}`} token={getToken()} location={location}
          component={MainApp} />
      </Switch>
    </Router>

  )
}
